import "../src/global.scss"
import "../src/tailwind.css"
import "error-polyfill"
import "normalize.css"
import { CsrfTokenProvider } from "../src/providers"
import { DefaultAvoProvider } from "@cultureamp/amplitude"
import { DynamicIntlProvider } from "@cultureamp/i18n-react-intl"
import { FrontendServices, defaultPreset } from "@cultureamp/next-services"
import Avo from "../src/Avo"
import type { AppProps } from "next/app"

if (process.env.NODE_ENV !== "production") {
  require("../mocks")
}

// Avo hidden for SSO Worlflow development to prevent 'next' button hiding behind iFrame. Add back prior to release

function App({ Component, pageProps }: AppProps) {
  return (
    <FrontendServices
      services={defaultPreset}
      options={{
        defaultTitle: "Sign in - Culture Amp",
        datadogConfiguration: {
          service: "authentication-ui",
          applicationId: "d6e55aff-234e-40b1-9103-950beb808c33",
          clientToken: "pub181fd52b21efa331b66993661e2fd528",
        },
      }}
    >
      <DynamicIntlProvider>
        <DefaultAvoProvider Avo={Avo}>
          <CsrfTokenProvider>
            <Component {...pageProps} />
          </CsrfTokenProvider>
        </DefaultAvoProvider>
      </DynamicIntlProvider>
    </FrontendServices>
  )
}

export default App
