import { useEffect, useState } from "react"
import { useRouter } from "next/router"

/**
 * Custom hook to get the value of a specific query parameter from the URL.
 *
 * @param param - The name of the query parameter to retrieve.
 * @returns The value of the query parameter as a string, or undefined if not found.
 */
export function useQueryParam(param: string) {
  const router = useRouter()
  const [value, setValue] = useState<string | undefined>()

  useEffect(() => {
    if (router.isReady) {
      const queryValue = router.query[param]
      if (typeof queryValue === "string") {
        setValue(queryValue)
      }
    }
  }, [router.query, param, router.isReady])

  return value
}
