import * as JWT from "jose"
import { Checks } from "../types/PasswordReset/password-reset-form"
import {
  GetSecretValueCommand,
  SecretsManagerClient,
} from "@aws-sdk/client-secrets-manager"
import { GoogleOAuthState, Token, TokenValue } from "../types"
import { NextApiRequest } from "next"
import { determineEnvironment } from "@cultureamp/frontend-env"
import { getCookie } from "cookies-next"
import React from "react"

const redirectCookieGetter = async (req: NextApiRequest): Promise<string> => {
  // Access the cookies from the request object
  const cookie = await getCookie("redirect_to", { req })
  return cookie || ""
}

export type Stack = "tenant" | "instance"

export interface Secret {
  apiKey: string
  faUrl: string
  hostUrl?: string
  tenantId?: string
  cryptoSecret: string
  oAuth: OauthOptions
  basicAuthForResetPassword: BasicAuthForResetPassword
}

export interface OauthOptions {
  google: GoogleOauthOption
}

interface GoogleOauthOption {
  clientId: string
  clientSecret: string
}
interface BasicAuthForResetPassword {
  username: string
  password: string
}

let cachedSecret: Record<string, Secret> = {}

/**
 * Clears the cached key
 */
export function clearCachedSecret() {
  cachedSecret = {}
}

/**
 * Retrieves the secret ID based on the stack and farm
 * @returns The secret ID
 */
export function getSecretId(stack: Stack, farm: string) {
  if (stack === "instance") {
    return `/authentication-ui/consumed-from/fusionauth-ops/instance`
  }

  return `/authentication-ui/consumed-from/fusionauth-ops/${farm}`
}

/**
 * Reads FA API Key from AWS secrets manager
 *
 * @returns The secret value
 */
export async function getFusionAuthSecret(
  req: NextApiRequest,
  stack: Stack = "tenant",
): Promise<Secret> {
  if (process.env.NODE_ENV === "development") {
    return {
      apiKey: process.env.FUSIONAUTH_APP_API_KEY ?? "",
      faUrl: process.env.FUSIONAUTH_SERVER_URL ?? "",
      ...(stack === "tenant"
        ? { hostUrl: process.env.FUSIONAUTH_APP_REDIRECT_HOST ?? "" }
        : {}),
      ...(stack === "tenant"
        ? { tenantId: process.env.FUSIONAUTH_DEFAULT_TENANT_ID ?? "" }
        : {}),
      cryptoSecret: process.env.APP_CRYPTO_SECRET ?? "",
      oAuth: {
        google: {
          clientId: process.env.GOOGLE_OAUTH_CLIENT_ID ?? "",
          clientSecret: process.env.GOOGLE_OAUTH_CLIENT_SECRET ?? "",
        },
      },
      basicAuthForResetPassword: {
        username: process.env.BASIC_AUTH_FOR_RESET_PASSWORD_USERNAME ?? "",
        password: process.env.BASIC_AUTH_FOR_RESET_PASSWORD_PASSWORD ?? "",
      },
    }
  }

  const farm = getFarmName(req)
  const cachedKey = `${stack}-${farm}`

  if (!cachedSecret[cachedKey]) {
    try {
      const smClient = new SecretsManagerClient({})
      const result = await smClient.send(
        new GetSecretValueCommand({
          SecretId: getSecretId(stack, farm),
        }),
      )
      const {
        authUiApiKey,
        faUrl,
        hostUrl,
        tenantId,
        cryptoSecret,
        oAuth,
        basicAuthForResetPassword,
      } = JSON.parse(result.SecretString!)

      cachedSecret[cachedKey] = {
        apiKey: authUiApiKey,
        faUrl: removeTrailingSlash(faUrl),
        ...(hostUrl ? { hostUrl } : {}),
        ...(tenantId ? { tenantId } : {}),
        cryptoSecret,
        oAuth,
        basicAuthForResetPassword,
      }
    } catch (e) {
      throw new Error(`Unable to read FusionAuth API key: ${e}`)
    }
  }

  return cachedSecret[cachedKey] as Secret
}

/**
 * Retrieves the farm name from `x-ca-farm` request header
 * @see https://github.com/cultureamp/web-gateway/blob/9908ead5c6ffa72e8e7a340008a213036f045b17/route/router.go#L151-L152
 * @see https://github.com/cultureamp/web-gateway/blob/9908ead5c6ffa72e8e7a340008a213036f045b17/forward/frontend/frontend_app.go#L53-L55
 *
 * @param req The incoming message
 * @returns A farm name or throws an error otherwise
 */
export function getFarmName(req?: NextApiRequest): string {
  const header = req?.headers["x-ca-farm"]
  if (!header) {
    throw new Error(
      "Unable to read request header `x-ca-farm` from incoming message",
    )
  }

  return Array.isArray(header) ? header[0]! : header
}

/**
 * Determinse the environment and retrieve environment name for Sentry
 *
 * @returns The environment name
 */
export function getEnvironmentName() {
  const environment = determineEnvironment()

  return environment.kind == "development" ? environment.name : environment.kind
}

/**
 * Determinse the error is instance of Error and get error message
 *
 * @returns The error message
 */
export const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message
  return JSON.stringify(error)
}

type EmptyObject<T> = { [K in keyof T]?: never }
type EmptyObjectOf<T> = EmptyObject<T> extends T ? EmptyObject<T> : never

/**
 * Determinse if an optional object is empty
 *
 * @returns if an optional object is empty
 */
export const isObjEmpty = <T extends object>(
  obj: T | null | undefined,
): obj is EmptyObjectOf<T> | null | undefined => {
  return !obj || Object.keys(obj).length === 0
}

/**
 * Checks if the user's email address input matches the valid email address format
 * and its length is not more than the valid email length
 *
 * @returns a boolean value
 */
export const isEmailValid = (email: string) => {
  const emailRegex = new RegExp(
    /^[^\s@]+@[^\s@\.]+\.[^\s@\.]+(\.[^\s@\.]+){0,93}$/,
  )
  const validEmailLength = 254
  const trimmedEmail = email?.trim()
  return (
    emailRegex.test(trimmedEmail) && trimmedEmail.length <= validEmailLength
  )
}

/**
 * Checks if the user's password input has at least 8 characters, 1 uppercase letter, 1 lowercase letter,
 * 1 symbol (including spaces and underscores) and both passwords match
 *
 * @returns an object of objects
 */
export const checkPasswordRequirements = (
  password: string,
  confirmPassword: string,
): Checks => {
  return {
    length: {
      text: "8 characters",
      fulfilled: password?.length >= 8 || confirmPassword?.length >= 8,
    },
    uppercase: {
      text: "1 upper case letter",
      fulfilled: /[A-Z]/.test(password) || /[A-Z]/.test(confirmPassword),
    },
    lowercase: {
      text: "1 lower case letter",
      fulfilled: /[a-z]/.test(password) || /[a-z]/.test(confirmPassword),
    },
    symbol: {
      text: "1 symbol (eg. !#$&)",
      fulfilled: /[\W_]/.test(password) || /[\W_]/.test(confirmPassword),
    },
    match: {
      text: "Confirm password match",
      fulfilled:
        password === "" || confirmPassword === ""
          ? false
          : password === confirmPassword,
    },
  }
}

/**
 * Check if the input is a string and it's not empty
 *
 * @param data any
 * @returns true if string is not empty and false otherwise
 */
export function isText(data: any): data is string {
  return typeof data === "string" && data.trim().length > 0
}

/**
 * Extracts and returns the host information from the `x-forwarded-host` header of the request
 * @param req The incoming message
 * @param options The options object to determine the host e.g. googleCallback
 * @returns The host
 */
export function getHost(
  req: NextApiRequest,
  options: { googleCallback?: boolean } = {},
) {
  let host = req.headers["x-forwarded-host"]

  const env = determineEnvironment()

  // Authorized redirect URIs configured in Google Cloud Platform for Google sign-in
  if (options.googleCallback && env.realm === "production") {
    switch (env.kind) {
      case "production-us":
        host = "id.cultureamp.com"
        break
      case "production-eu":
        host = "id.eu.cultureamp.com"
        break
      case "production-au":
        host = "id.au.cultureamp.com"
        break
    }
  }

  if (typeof host !== "string") {
    throw new Error("Unable to determine host from request header")
  }

  return host
}

/**
 * Constructs the base URL from the `x-forwarded-proto` and `x-forwarded-host` headers of the request
 * @param req The incoming message
 * @param options The options object to determine the host e.g. googleCallback
 * @returns The base URL
 */
export function getBaseUrl(
  req: NextApiRequest,
  options: { googleCallback?: boolean } = {},
) {
  const host = getHost(req, options)
  const scheme = req.headers["x-forwarded-proto"] ?? "https"

  if (typeof scheme !== "string") {
    throw new Error("Unable to determine scheme from request header")
  }

  return `${scheme}://${host}`
}

/**
 * Returns the domain of the host or undefined if not applicable
 */
export function extractDomain(host: string) {
  const parts = host?.split(".") ?? []

  return parts.length > 1 ? parts.slice(-2).join(".") : undefined
}

/**
 * Stringifies the Google OAuth state object
 */
export function stringifyGoogleOAuthState(state: GoogleOAuthState): string {
  return encodeURIComponent(JSON.stringify(state))
}

/**
 * Reads and parses the Google OAuth state object
 */
export function parseGoogleOAuthState(req: NextApiRequest): GoogleOAuthState {
  try {
    return JSON.parse(decodeURIComponent(req.query?.state as string) ?? "{}")
  } catch (error) {
    throw new Error(`Unable to parse Google OAuth state ${error}`)
  }
}

/**
 * Read account subdomain from the host url or undefined otherwise if not applicable
 * e.g. `bluebird` from `bluebird.au.cultureamp.com`
 */
export function readAccountSubdomain(req: NextApiRequest): string | undefined {
  const environment = determineEnvironment()
  const isGoogleCallbackRequest = Boolean(req.query?.state)

  // The authorised redirect URI for Google sign-in is configured with the ID subdomain.
  // To ensure correct read, the subdomain must be retained in the state object.
  // Check the Google sign-in and determine whether to retrieve the subdomain from the state object.
  // ! CAUTION Ensure that Google callback check is done before any other checks
  if (isGoogleCallbackRequest) {
    const { subdomain } = parseGoogleOAuthState(req)
    if (subdomain) {
      return subdomain
    }
  }

  if (environment.realm !== "production") {
    return req.query["fake-subdomain"] as string
  }

  const host = getHost(req)
  const pieces = host.split(".") ?? []

  return pieces.length > 2 ? pieces[0] : undefined
}

/**
 * Extracts the regional domain from a host or undefined otherwise if not applicable
 * e.g. `au.cultureamp.com` from `bluebird.au.cultureamp.com`
 */
export function extractRegionalDomain(host: string): string | undefined {
  const parts = host?.split(".") ?? []

  return parts.length > 2 ? parts.slice(1).join(".") : undefined
}

/**
 * Extracts the subdomain name from a host or undefined otherwise if not applicable
 * e.g. `sparrow` from `sparrow.bluebird.au.cultureamp.com` or `bluebird` from `bluebird.cultureamp.com`
 */
export function extractSubdomain(host: string): string | undefined {
  const parts = host?.split(".") ?? []

  return parts.length > 2 ? parts[0] : undefined
}

/**
 * Extracts the compound subdomain name from a host or undefined otherwise if not applicable
 * This method is being used for tracking reports
 * e.g. `sparrow.bluebird.au` from `sparrow.bluebird.au.cultureamp.com` or `bluebird` from `bluebird.cultureamp.com`
 */
export function extractCompoundSubdomain(host: string): string | undefined {
  const parts = host?.split(".") ?? []

  return parts.length > 2
    ? parts.slice(0, parts.length - 2).join(".")
    : undefined
}

export const sixDaysInSeconds = "518400"

/**
 * Retrieves an array of set-cookie headers
 */
export function makeSetCookieHeaders(tokens: Array<Token>) {
  const isProduction = process.env.NODE_ENV === "production"

  return tokens.map(({ kind, value, domain, maxAge, expires }) => {
    const env = getEnvironmentName()

    if (kind === "user-data") {
      // this really shouldn't be the case ever,
      // I'll leave it in case of malformed tokens and unit tests
      if (makeUserDataCookie(value) === "") {
        return ""
      }

      const attributes = [
        makeUserDataCookie(value),
        ...(isProduction ? ["Secure"] : []),
        ...(isProduction && domain ? [`Domain=${domain}`] : []),
        ...(maxAge ? [`Max-Age=${maxAge}`] : []),
        ...(expires ? [`Expires=${expires}`] : []),
        "Path=/",
        "SameSite=Lax",
      ]

      return attributes.join(";")
    } else if (kind === "user-data-by-region") {
      // this really shouldn't be the case ever,
      // I'll leave it in case of malformed tokens and unit tests
      if (makeUserDataCookieByRegion(env, value) === "") {
        return ""
      }

      const attributes = [
        makeUserDataCookieByRegion(env, value),
        ...(isProduction ? ["Secure"] : []),
        ...(isProduction && domain ? [`Domain=${domain}`] : []),
        "Path=/",
        "SameSite=Lax",
      ]

      return attributes.join(";")
    } else {
      let name = `cultureamp.${kind}-token`
      switch (kind) {
        case "jwt":
          name = `cultureamp.${env}.token`
          break
        case "refresh-token":
          name = `cultureamp.${env}.refresh-token`
          break
      }

      const attributes = [
        `${name}=${value}`,
        ...(isProduction ? ["Secure"] : []),
        ...(isProduction && domain ? [`Domain=${domain}`] : []),
        ...(maxAge ? [`Max-Age=${maxAge}`] : []),
        ...(expires ? [`Expires=${expires}`] : []),
        "Path=/",
        "SameSite=Lax",
        "HttpOnly",
      ]

      return attributes.join(";")
    }
  })
}

/**
 * Retrieves an array of set-cookie headers
 */
function makeUserDataCookie(token: string): string {
  try {
    const decoded = JWT.decodeJwt(token)
    const jwtToken = decoded as TokenValue
    let locale = jwtToken.locale ?? null
    if (locale) {
      locale = locale.replace(/_/g, "-")
    }
    const jwtObj = {
      locale: locale,
      employee_aggregate_id: jwtToken.effectiveUserId ?? "",
      account_aggregate_id: jwtToken.accountId ?? "",
    }
    const stringifiedValue = JSON.stringify(jwtObj)
    return `cultureamp.user-data=${encodeBase64(stringifiedValue)}`
  } catch (e) {
    return ""
  }
}

/**
 * Retrieves an array of set-cookie headers
 */
function makeUserDataCookieByRegion(env: string, token: string): string {
  try {
    const decoded = JWT.decodeJwt(token)
    const jwtToken = decoded as TokenValue
    let locale = jwtToken.locale ?? null
    if (locale) {
      locale = locale.replace(/_/g, "-")
    }
    const jwtObj = {
      locale: locale,
      employee_aggregate_id: jwtToken.effectiveUserId ?? "",
      account_aggregate_id: jwtToken.accountId ?? "",
    }
    const stringifiedValue = JSON.stringify(jwtObj)
    return `cultureamp.${env}.user-data=${encodeBase64(stringifiedValue)}`
  } catch (e) {
    return ""
  }
}

function encodeBase64(str: string): string {
  return Buffer.from(str).toString("base64")
}

/**
 * Retrieves the redirect url for successful logins
 */
export async function getRedirectUrl(
  req: NextApiRequest,
  subdomain: string,
): Promise<string> {
  let path = "/session/new_sign_in"
  const redirectCookie = await redirectCookieGetter(req)
  if (redirectCookie) {
    path = path + `?redirect=${encodeURIComponent(redirectCookie)}`
  }

  const baseUrl = getBaseUrl(req)
  if (baseUrl.startsWith("https://id.")) {
    return `${baseUrl.replace("id", subdomain)}${path}`
  } else if (baseUrl.startsWith("https://identity.")) {
    return `${baseUrl.replace("identity", subdomain)}${path}`
  }

  return `${baseUrl}${path}`
}

/**
 * Retrieves the Murmur's reset password URL for the given protocol and host
 *
 * @returns a string
 */
export function getMurmurResetPasswordUrl(protocol: string, host: string) {
  const resetPath = "session/password/new"

  if (host.startsWith("id.")) {
    return `${protocol}//${host.replace("id.", "identity.")}/${resetPath}`
  }

  return `${protocol}//${host}/${resetPath}`
}

/**
 * Retrieves the Murmur's SSO URL for the given protocol, host and subdomain
 *
 * @returns a string
 */
export function getMurmurSSOUrl(
  protocol: string,
  host: string,
  subdomain: string,
  redirect = "",
) {
  if (host.startsWith("id.")) {
    host = host.replace("id", subdomain)
  }

  if (redirect.length > 0) {
    redirect = `?redirect=${redirect}`
  }

  return `${protocol}//${host}/saml/${subdomain}${redirect}`
}

/**
 * Handles changes in the email input field's value and updates the workEmail state variable
 */
export const handleWorkEmailChange =
  (setWorkEmail: React.Dispatch<React.SetStateAction<string>>) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailInput = event.target.value
    setWorkEmail(emailInput)
  }

/**
 * Handles the onblur event on the email input field
 * to determine whether to display the email validation error message or not
 */
export const handleOnBlur =
  (
    workEmail: string,
    setShowInvalidEmailFormatError: React.Dispatch<
      React.SetStateAction<boolean>
    >,
    setErrorCounter: React.Dispatch<React.SetStateAction<number>>,
  ) =>
  () => {
    const valid = isEmailValid(workEmail) || !isText(workEmail)
    setShowInvalidEmailFormatError(!valid)
    if (valid) {
      setErrorCounter(0)
    } else {
      setErrorCounter(prevCount => prevCount + 1)
    }
  }

/**
 * Parses the query parameters from the URL
 *
 * @returns an object of key-value pairs
 */
export function getQueryParams(search: string) {
  const queryParams: { [key: string]: string } = {}

  search.split("&").forEach(param => {
    const [key, value] = param.split("=")
    queryParams[key || ""] = decodeURIComponent(value || "")
  })

  return queryParams
}

/**
 * Remove the trailing slash from the URL
 *
 * @returns an object of key-value pairs
 */
export function removeTrailingSlash(url: string): string {
  if (url.endsWith("/")) {
    return url.substring(0, url.length - 1)
  }
  return url
}

/**
 * Check if the host is a US subdomain
 * @param host
 * @returns
 */
export function isUSSubdomain(host: string): boolean {
  const splitHost = host.split(".")
  const regions = ["eu", "au"]
  return !splitHost.some(part => regions.includes(part))
}

/**
 * Get the sign in URL
 * @param host
 * @returns
 */
export function getSignInUrl(
  protocol: string,
  host: string,
  isEURegion: boolean,
): string {
  const splitHost = host.split(".")
  let subdomain = splitHost[0] ?? ""
  if (splitHost.length == 3 && ["au", "eu"].includes(subdomain)) {
    subdomain = "id"
  }

  const pattern = `${protocol}//{subdomain}{env}.cultureamp.com`
  const url = pattern
    .replace("{subdomain}", subdomain)
    .replace("{env}", isEURegion ? "" : ".eu")
  return url
}

/**
 * Check if the user is authenticated
 *
 * @returns True if the user is authenticated, false otherwise
 */
export function checkUserAuthentication(req: NextApiRequest): boolean {
  const authHeader = req.headers["x-ca-sgw-authorization"] as string
  if (authHeader) {
    const [bearerPlaceHolder, token] = authHeader.split(" ")

    return bearerPlaceHolder === "Bearer" && token !== undefined
  }

  return false
}

/**
 * Append `fake-subdomain` query parameter to a URL if not in production
 *
 * @returns Modified URL with fake-subdomain parameter if needed
 */
export function appendFakeSubdomainIfNeeded(
  url: string,
  fakeSubdomain?: string,
): string {
  const environment = determineEnvironment()
  if (environment.realm !== "production" && fakeSubdomain) {
    const urlObj = new URL(url, "http://dummy-base") // Use a dummy base to parse relative URLs
    if (!urlObj.searchParams.has("fake-subdomain")) {
      const separator = url.includes("?") ? "&" : "?"
      return `${url}${separator}fake-subdomain=${encodeURIComponent(fakeSubdomain)}`
    }
  }
  return url
}
