import { appendFakeSubdomainIfNeeded } from "../utils"
import { useEffect, useState } from "react"
import { useQueryParam } from "./useQueryParam"

/**
 * Custom hook to fetch and manage CSRF token.
 *
 * This hook fetches a CSRF token from the server and provides it along with a loading state.
 * It also handles aborting the fetch request if the component using this hook unmounts.
 *
 * @returns An object containing the CSRF token and a loading state.
 */
export function useCsrfToken() {
  const fakeSubdomain = useQueryParam("fake-subdomain")
  const [csrfToken, setCsrfToken] = useState<string>()

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal

    let apiEndpoint = "/app/auth/api/csrf"
    apiEndpoint = appendFakeSubdomainIfNeeded(apiEndpoint, fakeSubdomain)

    fetch(apiEndpoint, { cache: "no-store", signal })
      .then(res => res.json())
      .then(res => {
        setCsrfToken(res.csrfToken)
      })

    return () => {
      abortController.abort("abort duplicated requests")
    }
  }, [fakeSubdomain])

  return {
    csrfToken,
    isLoading: csrfToken ? false : true,
  }
}
